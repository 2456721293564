// src/components/MaintenancePage.js

import React, { useEffect, useState } from 'react';
import './MaintenancePage.css'; 

const MaintenancePage = () => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    // Set the date when maintenance will be over
    const maintenanceEndDate = new Date('October 20, 2024 23:59:59').getTime();

    const updateCountdown = () => {
      const now = new Date().getTime();
      const distance = maintenanceEndDate - now;

      if (distance < 0) {
        // Maintenance is over
        clearInterval(timer);
        // You can redirect or update the UI as needed
        // For example, you might want to reload the page or navigate to the home page
        window.location.reload();
      } else {
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        setTimeLeft({ days, hours, minutes, seconds });
      }
    };

    const timer = setInterval(updateCountdown, 1000);
    updateCountdown(); // Initialize countdown immediately

    // Cleanup on component unmount
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="maintenance-container">
      <div className="logo">
        <img
          src="https://www.appliedforensicresearchsciences.in/static/media/logo.ef0fed08cf16761e6311.png"
          alt="Forensic Solutions Logo"
        />
      </div>
      <h1>We'll Be Back Soon</h1>
      <p>
        Our website is currently undergoing scheduled maintenance. We apologize for any
        inconvenience and appreciate your patience.
      </p>

      {/* Optional Countdown Timer */}
      <div id="countdown">
        <div className="time">
          <span>{timeLeft.days}</span>
          <span>Days</span>
        </div>
        <div className="time">
          <span>{timeLeft.hours}</span>
          <span>Hours</span>
        </div>
        <div className="time">
          <span>{timeLeft.minutes}</span>
          <span>Minutes</span>
        </div>
        <div className="time">
          <span>{timeLeft.seconds}</span>
          <span>Seconds</span>
        </div>
      </div>

      <div className="contact">
        <p>For urgent matters, please contact us:</p>
        <a href="mailto:afrsciences@gmail.com">
          <i className="fas fa-envelope"></i> afrsciences@gmail.com
        </a>
        <a href="tel:+919926692487">
          <i className="fas fa-phone"></i> +91 99266 92487
        </a>
      </div>

      <footer>
        <p>&copy; 2024 Forensic Solutions. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default MaintenancePage;
